import React, { Component } from 'react';
import './index.scss';
import { isMobile } from "react-device-detect";
import logo from '../../../resources/assets/weyyak-logo1.svg';
import { connect } from "react-redux";
import { withRouter } from "react-router";



/**
 * Component Name - HeaderContentMiddle
 * This is a class component and it is the Middle Part in the Header Section that will consist of the logo of the Website.
 * @param { Object } props - Properties to the Component.
 * @returns { Object }
 */
class HeaderContentMiddle extends Component {
  constructor(props) {
    super(props);
    
  }


  componentWillUnmount() {
    // Clean up the media query listener
    // this.mediaQueryListener.remove();
  }

  render() {
    return (
      <div className= {this.props.locale=="en"?"logo-container":"logo-containerAr"} onClick={() => this.props.onLogoClick()}>
        <img className={isMobile ? "logoMobile":"logo"} src={logo} alt="Weyyak logo" />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    locale: state.locale,
   
  };
};
export default withRouter(
  connect(mapStateToProps,)(HeaderContentMiddle)
);

