/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */

import React, { Component } from "react";
import oResourceBundle from "app/i18n/";
import { withRouter } from "react-router-dom";
import Menu from "core/components/Menu";
import * as actionTypes from "app/store/action/";
import * as common from "app/utility/common";
import * as constants from "app/AppConfig/constants";
import { ENABLE_APP_MENU } from "app/AppConfig/features";
import UserMenu from "app/views/components/UserMenu/";
import Overlay from "core/components/Overlay";
import MenuItem from "core/components/MenuItem";
import Button from "core/components/Button/";
import User_icon from "app/resources/assets/newslider/User_menu.svg";
import Settings_icon from "app/resources/assets/newslider/settings.svg";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import downArrowOrange from "app/resources/assets/login/ic-user-darrow-orange.png";
import settingsIcon from "app/resources/assets/header/settings.svg";
import HandlerContext from "app/views/Context/HandlerContext";
import DefaultImage from "../../../resources/assets/myplaylistss.svg";
import Moviesimg from "../../../resources/assets/newslider/Movies.svg";
import LiveTvimg from "../../../resources/assets/newslider/Live TV.svg";
import Programsimg from "../../../resources/assets/newslider/Programs.svg";
import Seriesimg from "../../../resources/assets/newslider/Series.svg";
import Playlistimg from "../../../resources/assets/newslider/Playlist.svg";
import LanguageButton from "app/views/components/LanguageButton/";
import "./index.scss";
import * as features from "app/AppConfig/features";
import logo from "../../../resources/assets/weyyak-logo1.svg";

class AppMenu extends Component {
  constructor(props) {
    super(props);
    this.appMenu = React.createRef();
  }
  static contextType = HandlerContext;

  componentDidUpdate() {
    try {
      setTimeout(() => {
        this.appMenu &&
          this.appMenu.current &&
          this.appMenu.current.scrollTo(0, 0);
      }, 10);
    } catch (e) {
      console.log(e);
    }
  }

  onMenuItemClick(oEvent) {
    //Comment this to activate menu item click
    if (!ENABLE_APP_MENU) {
      oEvent.preventDefault();
    }
  }

  /**
   * Component Name - AppMenu
   * It will used to handle the drop down value from the user menu.
   * @param { Number, Event } index - Index of the item selected and eve- is event handler.
   * @returns { Object }
   */
  handleUserMenuDropDown(index, eve) {
    const userMenuList =
      this.props.locale === "en"
        ? constants.USER_MENU_DROP_DOWN_VALUE_ENG
        : constants.USER_MENU_DROP_DOWN_VALUE_ARB;
    const userMenuValue = userMenuList.filter(
      (item, itemIndex) => itemIndex === index
    );
    switch (userMenuValue[0].key) {
      case "acct": {
        this.props.history.push(
          `/${this.props.locale}/${constants.MY_ACCOUNT}/${constants.ACCOUNT_DETAILS}`
        );
        this.context.onAppBodyClicked();
        break;
      }
      case "activity": {
        this.props.history.push(
          `/${this.props.locale}/${constants.MY_ACTIVITY}/`
        );
        this.context.onAppBodyClicked();
        break;
      }
      case "mysubscription": {
        this.props.history.push(
          `/${this.props.locale}/${constants.MY_SUBSCRIPTION}/`
        );
        this.context.onAppBodyClicked();
        break;
      }
      case "logout": {
        common.deleteCookie(constants.COOKIE_USER_OBJECT);
        common.deleteCookie(constants.COOKIE_USER_TOKEN);
        common.DeleteGDPRCookie("GDPR_Cookies");
        common.DeleteGDPRCookie("cookies_accepted");
        localStorage.removeItem("Ramadan");
        sessionStorage.removeItem("subscribedUser");
        sessionStorage.removeItem("notSubscribedUser");
        this.props.fnForLogOut();
        break;
      }
      default: {
        break;
      }
    }
  }

  fnRenderThumbnailImages(item, DeviceOriented) {
    if (item.imagery[DeviceOriented]) {
      return item.imagery[DeviceOriented];
    } else {
      return DefaultImage;
    }
  }

  /**
   * Component Name - AppMenu
   * It is a render method of Menu Component, that will render the menu in Application.
   * @param {null}
   * @returns { Object }
   */
  render() {
    if (this.props.menuitems) {
      let sCategoryId = null;
      const oMenuItem = this.props.menuitems.filter((ele) => {
        if (ele.friendly_url.indexOf("premium") !== -1) {
          return true;
        }
        return false;
      });
      sCategoryId = oMenuItem[0] ? oMenuItem[0].id : null;
      localStorage.setItem("PremiumID", sCategoryId);
    }

    const touchClassName = isMobile !== undefined && isMobile ? "is-touch" : "";
    let userLogInStatus = null;
    try {
      userLogInStatus =
        common.getServerCookie(constants.COOKIE_USER_OBJECT) !== null
          ? JSON.parse(common.getServerCookie(constants.COOKIE_USER_OBJECT))
          : null;
    } catch (ex) {
      common.deleteCookie(constants.COOKIE_USER_OBJECT);
    }
    return (
      <React.Fragment>
        <Overlay show={this.props.show} onClick={this.props.closeButtonClick} />

        <Menu
          className={"app-menu"}
          show={this.props.show}
          closeButtonClick={this.props.closeButtonClick}
          showCloseBtn={this.props.showCloseBtn}
          ref={this.appMenu}
        >
          <div className="app-menu-item-container">
            <div className={this.props.locale=="en"?"logoSubscribeEn" :"logoSubscribeAR"}>
              <div  className={ this.props.locale =="en"?"logoMobilesidemenuEN":"logoMobilesidemenuAr"}>
            <img
             
              src={logo}
              alt="Weyyak logo"
            />
            </div>
            <div
              className={
                this.props.locale == "en"
                  ? "mobile-subscribeEN"
                  : "mobile-subscribeAr"
              }
            >
              {features.ENABLE_SUBSCRIPTION &&
              !this.props.isUserSubscribed &&
              common.showSubscription(this.props.history.location.pathname) ? (
                <Button
                  style={{ "display": "none","white-space":"nowrap"," width": "157px" }}
                  className="subscribe-btn right-subscribe-btn"
                  onClick={this.context.onSubscribeButtonClick}
                >
                  {oResourceBundle.subscribe_landing_text}
                </Button>
              ) : null}
            </div>

            </div>
            <div className="menu-items">
              {isMobile ? (
                <div className= {this.props.locale=="ar"?"head-links-mobile1":"head-links-mobile1En"}>
                  <ul>
                    {this.props.HeaderMenu.map((ele, index) => {
                      let cdetailsContent;
                      let imageSource;
                      if (ele.title == "أفلام"    || ele.title =="Movies") {
                        imageSource = Moviesimg;
                        cdetailsContent = oResourceBundle.movies;
                      } else if (ele.title == "مسلسلات" || ele.title =="Series") {
                        imageSource = Seriesimg;
                        cdetailsContent = oResourceBundle.series;
                      } else if (ele.title == "قنوات البث المباشر" || ele.title =="LiveTV") {
                        imageSource = LiveTvimg;
                        cdetailsContent = oResourceBundle.LiveTv;
                      } else if (ele.title == "برامج "||ele.title=="Programs") {
                        imageSource = Programsimg;
                        cdetailsContent = oResourceBundle.programs;
                      } else if (ele.title == "مسرحيات" || ele.title =="Play") {
                        imageSource = Playlistimg;
                        cdetailsContent = oResourceBundle.plays;
                      }

                      return (
                        <li>
                          <Link
                            className={
                              this.props.location.pathname.substring(
                                this.props.location.pathname.lastIndexOf("/") +
                                  1
                              ) === ele.url
                                ? "activelink"
                                : ""
                            }
                            to={`/${this.props.locale}/contents/${ele.url}`}
                          >
                            <img
                              src={imageSource}
                              alt=""
                              className= {this.props.locale=="en"?"title-imagesMenuEn" :"title-imagesMenuAr"}
                            />{" "}
                            {ele.title}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                ""
              )}
              <div
                // className={isMobile ?  "menusignmobile":"menu-sign-in"}
                className="menu-sign-in"
              >
                <img
                  className="user-icon1"
                  src={User_icon}
                  onClick={this.props.onSignInClick}
                />

                {(this.props.loginDetails !== null &&
                  this.props.loginDetails.bSuccessful) ||
                userLogInStatus !== null ? (
                  <React.Fragment>
                    <UserMenu
                      onSignInClick={this.props.onSignInClick}
                      showUserMenuDropDown={this.props.showUserMenuDropDown}
                      downArrowIcon={downArrowOrange}
                      className="user-menu-container"
                      handleUserMenuDropDown={(index, eve) =>
                        this.handleUserMenuDropDown(index, eve)
                      }
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Button
                      className="sign-in-btn"
                      onClick={this.props.onSignInClick}
                    >
                      {oResourceBundle.login}
                    </Button>
                    {isMobile ? (
                      <Button
                        className= {this.props.locale=="en"?"settings-btn-mobile":"settings-btn-mobileAr"}
                        icon={settingsIcon}
                        onClick={() =>
                          common.fnNavTo.call(
                            this,
                            "/" + this.props.locale + "/settings"
                          )
                        }
                      >
                        <span style={{ color: "#757575" }}>
                          {oResourceBundle.settings}
                        </span>
                      </Button>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                )}
              </div>
              {this.props.menuitems.map((item) => {
                return item.title === "My Playlist" ||
                  item.title === "قائمتي" ? (
                  <div className="mobile-myplaylist">
                    <Link
                      key={item.id}
                      to={`${item.friendly_url}`}
                      tabIndex={this.props.show ? "0" : "-1"}
                    >
                      <MenuItem
                        text={item.title}
                        id={item.id}
                        show={this.props.show}
                        friendly_url={item.friendly_url}
                        seo_description={item.seo_description}
                        type={item.type}
                        onClick={this.onMenuItemClick.bind(this)}
                        aria-label={item.title}
                        img={this.fnRenderThumbnailImages(item, "mobile-menu")}
                      />
                    </Link>
                  </div>
                ) : null;
              })}
            </div>

            <div className="static-menu">
              <div className= {this.props.locale =="en" ? "languageButtonMobile":"languageButtonMobileAr"}>
                <LanguageButton
                  locale={this.props.locale}
                  onLanguageButtonCLick={this.props.onLanguageButtonClick}
                />
              </div>
              {this.props.staticMenuItems.map((item, index) =>
                index === 0 ? (
                  <Link
                    key={item.id}
                    to={`/${this.props.locale}/static/${item.friendly_url}`}
                    tabIndex={this.props.show ? "0" : "-1"}
                  >
                    <MenuItem
                      text={
                        item.title +
                        `<strong class="z5-menu-text">&nbsp;${oResourceBundle.weyyak}</strong>`
                      }
                      showHTMLText={true}
                      id={item.id}
                      friendly_url={item.friendly_url}
                      plain_text={item.plain_text}
                      subtitle={item.subtitle}
                      title={item.title}
                      htmlText={item.text}
                      onClick={this.onMenuItemClick.bind(this)}
                      aria-label={item.title}
                      img={"NO"}
                    />
                  </Link>
                ) : (
                  <Link
                    key={item.id}
                    to={`/${this.props.locale}/static/${item.friendly_url}`}
                    tabIndex={this.props.show ? "0" : "-1"}
                  >
                    <MenuItem
                      text={item.title}
                      showHTMLText={true}
                      id={item.id}
                      friendly_url={item.friendly_url}
                      plain_text={item.plain_text}
                      subtitle={item.subtitle}
                      title={item.title}
                      htmlText={item.text}
                      onClick={this.onMenuItemClick.bind(this)}
                      aria-label={item.title}
                      img={"NO"}
                    />
                  </Link>
                )
              )}
              {isMobile ? (
                ""
              ) : (
                <div
                  className={
                    this.props.locale === "ar"
                      ? "settings-familyAr"
                      : "settings-family"
                  }
                >
                  <img
                    className={
                      this.props.locale === "ar"
                        ? "settings-btnAr"
                        : "settings-btn"
                    }
                    src={Settings_icon}
                    onClick={() =>
                      common.fnNavTo.call(
                        this,
                        "/" + this.props.locale + "/settings"
                      )
                    }
                  />
                  <h4
                    className={
                      this.props.locale === "ar"
                        ? "settings-textAr"
                        : "settings-text"
                    }
                  >
                    {oResourceBundle.settings}
                  </h4>
                </div>
              )}
              {/* <hr></hr>
              <div style={{ color: 'gray' }}>
                <h5><b>Version</b>: {constants.BUILD_VERSION_NUMBER}</h5>
              </div> */}
            </div>
          </div>
        </Menu>
      </React.Fragment>
    );
  }
}

/**
 * Component Name - AppMenu
 * method that maps state to props.
 * @param {Object} state - state from redux store.
 * @return {Object} - state mapped to props
 */
const mapStateToProps = (state) => {
  return {
    locale: state.locale,
    loginDetails: state.loginDetails,
  };
};
/**
 * Component Name - AppMenu
 * method that maps state to props.
 * @constructor
 * @param {Object} dispatch - dispatcher from store.
 * @return {Object} - dispatchers mapped to props
 */
const mapDispatchToProps = (dispatch) => {
  //dispatch action to redux store
  return {
    fnForLogOut: () => {
      dispatch(actionTypes.fnForLogOut());
    },
    fnHeaderMenu: (fnSuccess, fnFailed) => {
      dispatch(actionTypes.fnHeaderMenu(fnSuccess, fnFailed));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppMenu)
);
