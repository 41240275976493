import React, { Component } from "react";
import "./index.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as features from "app/AppConfig/features";
import oResourceBundle from "app/i18n/";
import HandlerContext from "app/views/Context/HandlerContext";
import { Link } from "react-router-dom";
import { fnConstructContentURL } from "app/utility/common";
import * as actionTypes from 'app/store/action/';

class MobileSearch extends Component {
  static contextType = HandlerContext;

  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    // Clean up the media query listener
  }

  render() {
    return (
      <div className="mobile-layout">
        {/* <h1 style={{ color: "red", position: "relative", margin: "0" }}>
          Durgaaaaaaashgdfsdjhgfhd
        </h1> */}

        <input
          type="text"
          placeholder={oResourceBundle.search_placeholder}
          maxLength="100"
          autoComplete="off"
          className="search-input-mobile"
          onClick={(evt) => this.context.onSearchInputClicked(evt)}
          aria-invalid="false"
          ref="search-input"
          onChange={features.ENABLE_SEARCH ? this.props.handleSearchInputText : null}
          value={this.props.userInputText}
        />

        {this.props.userSearchResponseList.length > 0 && (
          <div className="select-box-container">
            <div className="select-box">
              <div className="select-box-elements">
                {this.props.userSearchResponseList.map((ele, index) => (
                  <Link
                    aria-label={ele.title}
                    aria-required="true"
                    key={ele.id}
                    to={`/${this.props.locale}${fnConstructContentURL(ele.content_type, ele)}`}
                  >
                    <div className="select-element">{ele.title}</div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userSearchResponseList: state.userSearchResponseList,
  userInputText: state.userInputText,
  isUserSubscribed: state.bIsUserSubscribed,
});

const mapDispatchToProps = (dispatch) => ({
  fnSearchUserInput: (
    sLocale,
    userInputText,
    bUpdateSearchInput,
    fnUserSearchResponseListError,
    fnSearchSuccess
  ) => {
    dispatch(
      actionTypes.fnSearchUserInput(
        sLocale,
        userInputText,
        bUpdateSearchInput,
        fnUserSearchResponseListError,
        fnSearchSuccess
      )
    );
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileSearch));
